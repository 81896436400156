import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { useState } from "react";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
import { HelpPopup } from "../../../components/widgets/help-popup";
import { Popup } from "../../../components/widgets/popup";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <p><em parentName="p">{`TuringTrader.com`}</em>{` caters to `}<em parentName="p">{`DIY investors`}</em>{`, and our `}<a parentName="p" {...{
        "href": "/company/terms-of-use/"
      }}>{`Terms of Use`}</a>{` explicitly limit the use of `}<em parentName="p">{`TuringTrader`}</em>{` portfolios to personal and non-commercial use. We appreciate advisers using our portfolios to provide better service to their clients. However, the use of `}<em parentName="p">{`TuringTrader`}</em>{`'s portfolios for the benefit and on behalf of others requires a separate and individually negotiated agreement.`}</p>
    <p>{`Here is the general outline:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Bertram Enterprises`}</em>{` is not an investment adviser and does not provide investment advice. Instead, the signals are considered a publication of regular and general circulation. `}<em parentName="p">{`TuringTrader`}</em>{`'s `}<a parentName="p" {...{
            "href": "/company/disclaimer/"
          }}>{`disclaimer`}</a>{` applies, even when used by a third party for the purpose of providing investment advice.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The agreement only covers the advisor, its employees, and sub-processors to the extent required for performing its wealth management services. Any other use, be it personal or for the benefit of others, paid or pro bono, is not part of this agreement and requires separate subscriptions to TuringTrader.com. `}<em parentName="p">{`TuringTrader`}</em>{`'s `}<a parentName="p" {...{
            "href": "/company/terms-of-use/"
          }}>{`Terms of Use`}</a>{` apply.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Bertram Enterprises`}</em>{` provides asset allocations for its model portfolios. The advisor may access this information through the `}<em parentName="p">{`TuringTrader`}</em>{` website or API.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Bertram Enterprises`}</em>{` performs its best efforts to provide correct signals in a timely manner. Typically, the signals are expected before 7 pm Pacific Time. A second pass which should remedy any data issues or server outages is expected to be completed before 9 pm Pacific Time. However, adhering to this schedule this might not always be possible due to the complexity of the systems involved, including application software, simulation frameworks, and operating systems, and many dependencies on third-party services, including data providers, network infrastructure, and cloud computing. Should signals become delayed, `}<em parentName="p">{`Bertram Enterprises`}</em>{` will make reasonable efforts to provide the signals no later than 10 am Eastern on the trading day. Beyond this, `}<em parentName="p">{`Bertram Enterprises`}</em>{` cannot be held liable.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`TuringTrader`}</em>{`'s signals, algorithms, and research reports are confidential. The advisor, its employees, and sub-processors are required to protect the confidentiality of this information with the same diligence that they would use for their own confidential information.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The subscription includes access to all public model portfolios, including future revisions and releases. However, the subscription does not cover R&D, training, and technical support. Should such work be required, this work will be completed through `}<a parentName="p" {...{
            "href": "https://www.bertram.solutions/"
          }}>{`Bertram Solutions LLC`}</a>{` and billed separately.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`For its services, `}<em parentName="p">{`Bertram Enterprises`}</em>{` charges a subscription fee. An initial one-time setup fee is followed by a fixed monthly fee, paid in arrears. This fee does not change based on the number of clients served, the number of models used, or the assets under management. However, the fee shall be renegotiated every 12 months.`}</p>
      </li>
    </ol>
    <p>{`We are here to help; please reach out.`}</p>
    <ButtonPrimary text="Send Us a Message" to="/company/contact/" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      